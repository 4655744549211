import React, { Fragment, useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useHistory, useParams, Link } from "react-router-dom";
import moment from "moment-timezone";

import * as Sentry from "@sentry/react";
import TextInput from "../text-input";
import PhoneInput from "react-phone-number-input";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Loader from "../loader";
import formatStage from "../../helpers/format-stage";
import currencyFormatter from "../../helpers/currencyFormatter";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";

import { useMediaQuery } from "react-responsive";

import CollapsibleTableDesktop from "./single-prospect-lead-table-desktop";
import CollapsibleTableMobile from "./single-prospect-lead-table-mobile";

// mui imports
import Checkbox from "@mui/material/Checkbox";

let toastOptions = {
  position: "top-right",
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const parseStage = (stage, stageOptions) => {
  let option = stageOptions.filter((option) => option.value === stage);

  return option || false;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "emailFieldChanged": {
      let emails = [...state.prospectEmails];
      emails[action.index] = action.value;

      return {
        ...state,
        prospectEmails: emails,
      };
    }

    case "phoneNumberChanged": {
      let phoneNumbers = [...state.phoneNumbers];
      phoneNumbers[action.index].number = action.value;

      return {
        ...state,
        phoneNumbers,
      };
    }

    case "phoneCountryCodeChanged": {
      let phoneNumbers = [...state.phoneNumbers];
      phoneNumbers[action.index].countryCode = action.value;

      return {
        ...state,
        phoneNumbers,
      };
    }

    case "dataDownloaded": {
      let data = action.value;

      let stage = parseStage(data.stage, state.stageOptions);

      if (!stage.length) {
        stage = null;
      }

      if (data?.additionalUsers?.length) {
        data.additionalUsers = data.additionalUsers.map((i) => {
          if (i?.relationship) {
            i.relationship = {
              label: i.relationship,
              value: i.relationship,
            };
          }

          return {
            ...i,
          };
        });
      }

      return {
        ...state,
        loading: false,
        prospectData: action.value,
        phoneNumbers: data.phoneNumbers,
        selectedOwner: {
          label: data.assigneeName,
          value: data.assigneeId,
        },
        selectedStage: stage,
        prospectFirstName: data.firstName,
        prospectLastName: data.lastName,
        prospectEmails: data.emails,
        companyName: data.companyName ?? "",
        isBusiness: data.isBusiness ?? false,
        tags: data.tags.map((item) => {
          return { label: item, value: item };
        }),
        primaryContactTitle: data.primaryContactTitle ?? "",
        additionalUsers: data?.additionalUsers?.length
          ? data?.additionalUsers
          : [],
      };
    }

    case "phoneNumberFieldAdded": {
      let phoneNumbers = [...state.phoneNumbers];

      phoneNumbers.push({
        countryCode: "US",
        number: "",
        order: phoneNumbers.length + 1,
        phoneType: "mobile",
      });

      return {
        ...state,
        phoneNumbers,
      };
    }
    case "phoneNumberFieldDeleted": {
      let phoneNumbers = [...state.phoneNumbers];
      phoneNumbers.splice(action.index, 1);

      return {
        ...state,
        phoneNumbers,
      };
    }

    case "emailFieldAdded": {
      let emails = [...state.prospectEmails];

      emails.push("");

      return {
        ...state,
        prospectEmails: emails,
      };
    }
    case "emailFieldDeleted": {
      let emails = [...state.prospectEmails];
      emails.splice(action.index, 1);

      return {
        ...state,
        prospectEmails: emails,
      };
    }

    case "additionalUserFieldAdded": {
      let additionalUsers = [...state.additionalUsers];

      additionalUsers.push({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: {
          countryCode: "US",
          number: "",
        },
        relationship: " ",
      });

      return {
        ...state,
        additionalUsers,
      };
    }
    case "additionalUserFieldDeleted": {
      let additionalUsers = [...state.additionalUsers];
      additionalUsers.splice(action.index, 1);

      return {
        ...state,
        additionalUsers,
      };
    }

    case "additionalUserFieldChanged": {
      if (!action.field || isNaN(action.index)) {
        return state;
      }

      let additionalUsers = [...state.additionalUsers];

      additionalUsers[action.index][action.field] = action.value;

      return {
        ...state,
        additionalUsers,
      };
    }

    case "additionalUserPhoneFieldChanged": {
      if (!action.field || isNaN(action.index)) {
        return state;
      }

      let additionalUsers = [...state.additionalUsers];

      additionalUsers[action.index].phoneNumber[action.field] = action.value;

      return {
        ...state,
        additionalUsers,
      };
    }

    default:
      break;
  }
  return state;
};

const SingleProspectContent = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const initialState = {
    loading: true,
    phoneEnabled: false,
    ownerFieldEnabled: false,
    tagFieldEnabled: false,
    stageFieldEnabled: false,
    prospectData: null,
    prospectFirstName: "",
    prospectLastName: "",
    prospectEmails: [""],
    additionalUsers: [],
    phoneNumbers: [
      {
        countryCode: "US",
        number: "",
        phoneType: "mobile",
        order: 1,
      },
    ],
    prospectTagOptions: [
      { label: "RFP", value: "rfp" },
      { label: "Phone Call", value: "phoneCall" },
    ],
    selectedStage: null,
    selectedOwner: null,
    prospectTags: [],
    stageOptions: [
      {
        label: "New Prospect",
        value: "newLead",
        stageId: 11,
      },
      {
        label: "In Progress",
        value: "inProgress",
        stageId: 2,
      },
      {
        label: "Archived",
        value: "archived",
        stageId: 8,
      },
      {
        label: "Warm Prospect",
        value: "warmProspect",
        stageId: 7,
      },
      // {
      //   label: "No Reply",
      //   value: "noReply",
      //   stageId: 16,
      // },
      {
        label: "Hot Prospect",
        value: "hotProspect",
        stageId: 15,
      },
      // {
      //   label: "Unqualified",
      //   value: "unqualified",
      //   stageId: 5,
      // },
      // {
      //   label: "In Limbo",
      //   value: "inLimbo",
      //   stageId: 1,
      // },
      // {
      //   label: "Closed - No Reply",
      //   value: "closedNoReply",
      //   stageId: 10,
      // },
      // {
      //   label: "Closed - Ghost",
      //   value: "closedGhost",
      //   stageId: 9,
      // },
      {
        label: "Repeat Client",
        value: "repeatClient",
        stageId: 4,
      },
      // {
      //   label: "Closed - Lost to Competitor",
      //   value: "closedCompetitor",
      //   stageId: 7,
      // },
      // {
      //   label: "Tip Complete",
      //   value: "tripComplete",
      //   stageId: 4,
      // },
      // {
      //   label: "Signed Contract",
      //   value: "signedContract",
      //   stageId: 4,
      // },
    ],

    isBusiness: false,
    companyName: "",
    primaryContactTitle: "",
    additionalUserRelationshipOptions: [
      {
        label: "Assistant",
        value: "Assistant",
      },
      {
        label: "Company Representative",
        value: "Company Representative",
      },
      {
        label: "Family Member",
        value: "Family Member",
      },
      {
        label: "Service Provider",
        value: "Service Provider",
      },
    ],
  };

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdminOrAd = userRoles.some((role) => {
    return "Super Admin" === role || "Account Director" === role;
  });

  const salesAssistant = userRoles.some((role) => {
    return "Sales Assistant" === role;
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    loadOwnerOptions();
    loadProspectData(id);

    document.title = "Edit Prospect | Flight Deck by evoJets";
  }, []);

  const handlePhoneNumberChange = (value) => {
    dispatch({
      type: "fieldChanged",
      field: "prospectPhone",
      value: value,
    });
  };

  const loadProspectData = async (prospectId) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai
      .auth(token)
      .get(`/api/prospects/${prospectId}`)
      .catch((err) => {
        toast.error("Unauthorized!", toastOptions);
        history.push("/prospects");
        return err.response;
      });

    if (data) {
      dispatch({ type: "dataDownloaded", value: data });

      console.log("DATA", data);
      return data;
    } else {
      toast.error("Unauthorized!", toastOptions);
      history.push("/prospects");
    }
  };
  //me
  // auth0|5fe3c41e81637b00685cb5ff
  //alex
  // auth0|5fe4914e54ea200069c7b5b6

  //lead - 618ef10ac92d780018caa634

  // prospect - 618ef0e7c92d780018caa62f

  const loadOwnerOptions = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let { data } = await ai.auth(token).get(`/api/users/roles/dynamicSales`);

    if (data.users.length) {
      let results = data.users.map((user) => {
        return { value: user.user_id, label: user.name, email: user.email };
      });

      dispatch({ type: "fieldChanged", field: "ownerOptions", value: results });

      return results;
    } else {
      return [];
    }
  };

  const handleOwnerSelection = (selectedOwner) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedOwner",
      value: selectedOwner,
    });
    return selectedOwner;
  };

  const handleProspectTagSelection = (selectedTags) => {
    dispatch({
      type: "fieldChanged",
      field: "prospectTags",
      value: selectedTags,
    });
    return selectedTags;
  };

  const handleStageSelection = async (selectedStage) => {
    dispatch({
      type: "fieldChanged",
      field: "selectedStage",
      value: selectedStage,
    });
    return selectedStage;
  };

  const handlePhoneCountryChange = (countryCode) => {
    console.log(countryCode);
    dispatch({
      type: "fieldChanged",
      field: "prospectCountryCode",
      value: countryCode,
    });
  };

  const handleTextInputChange = (e) => {
    dispatch({
      type: "fieldChanged",
      field: e.currentTarget.name,
      value: e.currentTarget.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let updateBody = {
      firstName: state.prospectFirstName,
      lastName: state.prospectLastName,
      emails: state.prospectEmails,
      countryCode: state.prospectCountryCode,
      stage: state.selectedStage.value,
      tags: state.prospectTags?.length
        ? state.prospectTags.map((item) => item.value)
        : [],
      // source: "",
      assigneeName: state?.selectedOwner?.label,
      assigneeId: state?.selectedOwner?.value,
      phoneNumbers: [...state.phoneNumbers],
      isBusiness: state.isBusiness,
      companyName: state.companyName,
      additionalUsers: state?.additionalUsers ?? [],
      primaryContactTitle: state.primaryContactTitle,
    };

    if (updateBody?.additionalUsers?.length) {
      updateBody?.additionalUsers.map((i) => {
        if (i?.relationship?.value) {
          i.relationship = i?.relationship?.value;
        }
        i.isAuthorizedSigner = true;
        return {
          ...i,
        };
      });
    }

    console.log(updateBody);

    let response = await ai
      .auth(token)
      .put(`/api/prospects/${id}`, updateBody)
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });

    if (response) {
      console.log(response.data);

      if (!response?.data?.userHasAccess) {
        toast.error(
          "This Prospect already exists. Contact your AD to update ownership.",
          toastOptions
        );

        return;
      }

      if (
        response?.data?.userHasAccess &&
        response.data.prospectExisted &&
        response.data?.prospectData
      ) {
        toast.success(
          "This prospected existed already. No prospect data has been updated.",
          toastOptions
        );
      }

      dispatch({
        type: "fieldChanged",
        field: "prospectCreationResponse",
        value: response.data,
      });

      let label = `${response.data?.prospectData?.firstName} ${response.data?.prospectData?.lastName} - ${response.data?.prospectData?.emails[0]}`;

      if (response.data?.prospectData?.companyName) {
        label = `${label} - ${response.data?.prospectData?.companyName}`;
      }

      let phone = "";

      let i = response.data?.prospectData;

      if (i?.phoneNumbers?.length) {
        phone = i.phoneNumbers[0]?.number;
      }

      let countryCode = "";

      if (i?.phoneNumbers?.length) {
        countryCode = i.phoneNumbers[0]?.countryCode;
      }

      toast.success(
        `Prospect ${response.data?.prospectData?.firstName} ${response.data?.prospectData?.lastName} was successfully updated.`,
        toastOptions
      );

      // history.push({ pathname: `/prospects/` });

      return;
    }
  };

  const handleAddPhoneNumberField = () => {
    dispatch({ type: "phoneNumberFieldAdded" });
  };

  const handleDeletePhoneNumberField = (index) => {
    dispatch({ type: "phoneNumberFieldDeleted", index });
  };

  const handleAddEmailField = () => {
    dispatch({ type: "emailFieldAdded" });
  };

  const handleDeleteEmailField = (index) => {
    dispatch({ type: "emailFieldDeleted", index });
  };

  const handleAddAdditionalUserField = () => {
    dispatch({ type: "additionalUserFieldAdded" });
  };

  const handleDeleteAdditionalUserField = () => {
    dispatch({ type: "additionalUserFieldDeleted" });
  };

  const prepareTableData = (prospectData) => {
    const consolidatedLead = prospectData.leadData.map((lead) => {
      let correspondingQuotes = prospectData.quoteData.filter((quote) =>
        lead.quotes.includes(quote._id)
      );

      lead.quoteData = correspondingQuotes;

      return lead;
    });

    return consolidatedLead;
  };

  const handleCheckboxChange = (e) => {
    console.log("EVENT", e.target.name);

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: e.target.checked,
    });
  };

  return (
    <Fragment>
      {true && !state.loading && (
        <div className="content-blockk">
          <h2 className="mt-20 centered mb-20">Lead History</h2>

          {user && (
            <>
              {/* <JSONPretty id="json-pretty" data={user}></JSONPretty> */}
              {/* <JSONPretty
                id="json-pretty"
                data={state.prospectData}
              ></JSONPretty> */}
            </>
          )}

          {!state.loading && !isMobile && (
            <CollapsibleTableDesktop
              rows={prepareTableData(state.prospectData)}
            ></CollapsibleTableDesktop>
          )}

          {!state.loading && isMobile && (
            <CollapsibleTableMobile
              rows={prepareTableData(state.prospectData)}
            ></CollapsibleTableMobile>
          )}
        </div>
      )}

      <h2 className="centered mt-20">
        {props.sectionTitle ? props.sectionTitle : "Edit Contact"}
      </h2>

      <div className="form-group fb-100 centered ml-0">
        <label className="base-label">Is This a Business Client?</label>
        <Checkbox
          checked={state.isBusiness}
          onChange={handleCheckboxChange}
          name="isBusiness"
        />
      </div>

      {state.loading && <Loader></Loader>}

      {!state.loading && (
        <form
          className="base-form two-column"
          id="createProspectForm"
          onSubmit={handleSubmit}
        >
          {(superAdminOrAd || salesAssistant) && (
            <div className="form-group">
              <label className="base-label" htmlFor="selectedOwner">
                Contact Owner
              </label>
              <Select
                key={`selectedOwner-${state.selectedOwner}`}
                className={`prospect-owner-select prospect-form-select ${
                  state.ownerFieldEnabled ? "" : "selectDisabledTillClicked"
                }`}
                classNamePrefix="select"
                value={state.selectedOwner}
                options={state.ownerOptions}
                //   defaultValue={state.}
                placeholder="Prospect Owner"
                onChange={handleOwnerSelection}
                name="selectedOwner"
                onFocus={() =>
                  dispatch({
                    type: "fieldChanged",
                    field: "ownerFieldEnabled",
                    value: true,
                  })
                }
              ></Select>
            </div>
          )}

          <div className="form-group">
            <label className="base-label" htmlFor="prospectTags">
              Contact Tags
            </label>

            <CreatableSelect
              key={`selectedOwner-${
                state.prospectTags && state.prospectTags[0]
              }`}
              className={`tag-select prospect-form-select  ${
                state.tagFieldEnabled ? "" : "selectDisabledTillClicked"
              }`}
              classNamePrefix="select"
              isMulti
              value={state.prospectTags}
              options={state.prospectTagOptions}
              //   defaultValue={state.}
              placeholder="Prospect Tags"
              onChange={handleProspectTagSelection}
              name="prospectTags"
              onFocus={() =>
                dispatch({
                  type: "fieldChanged",
                  field: "tagFieldEnabled",
                  value: true,
                })
              }
            ></CreatableSelect>
          </div>

          <div className="form-group">
            <label className="base-label" htmlFor="selectedStage">
              Contact Stage
            </label>
            <Select
              key={`stage-${state.selectedStage}`}
              className={`stage-select prospect-form-select  ${
                state.stageFieldEnabled ? "" : "selectDisabledTillClicked"
              }`}
              classNamePrefix="select"
              value={state.selectedStage}
              options={state.stageOptions}
              placeholder="Prospect Stage"
              onChange={handleStageSelection}
              name="selectedStage"
              onFocus={() =>
                dispatch({
                  type: "fieldChanged",
                  field: "stageFieldEnabled",
                  value: true,
                })
              }
            ></Select>
          </div>

          {!state.isBusiness && (
            <>
              <h4 className="centered fb-100">Primary Signer Information</h4>

              <TextInput
                name="prospectFirstName"
                label="First Name"
                handleChange={handleTextInputChange}
                value={state.prospectFirstName}
                disabledTillClicked
                containerClasses="ml-0 mr-2pct-important"
              ></TextInput>
              <TextInput
                name="prospectLastName"
                label="Last Name"
                handleChange={handleTextInputChange}
                value={state.prospectLastName}
                disabledTillClicked
                containerClasses="mr-0 ml-2pct-important"
              ></TextInput>
            </>
          )}

          {state.isBusiness && (
            <>
              <TextInput
                name="companyName"
                label="Company Name"
                handleChange={handleTextInputChange}
                value={state.companyName}
                disabledTillClicked
              ></TextInput>

              <h4 className="centered fb-100">Primary Signer Information</h4>

              <TextInput
                name="prospectFirstName"
                label="First Name"
                handleChange={handleTextInputChange}
                value={state.prospectFirstName}
                disabledTillClicked
              ></TextInput>
              <TextInput
                name="prospectLastName"
                label="Last Name"
                handleChange={handleTextInputChange}
                value={state.prospectLastName}
                disabledTillClicked
              ></TextInput>

              <TextInput
                name="primaryContactTitle"
                label="Primary Signer's Title"
                handleChange={handleTextInputChange}
                value={state.primaryContactTitle}
                disabledTillClicked
                containerClasses="fb-100"
              ></TextInput>
            </>
          )}

          {state.prospectEmails.map((item, index) => {
            return (
              <div className="fb-100 mr-0" key={`emails-${index}`}>
                <TextInput
                  name={`prospectEmail[${index}]`}
                  label={`Email #${index + 1}`}
                  type="email"
                  handleChange={(e) => {
                    console.log(
                      `prospectEmails[${index}]`,
                      e.currentTarget.value
                    );
                    dispatch({
                      type: "emailFieldChanged",
                      index: index,
                      value: e.currentTarget.value,
                    });
                  }}
                  key={`prospectEmail[${index}]-key`}
                  containerClasses="mr-0"
                  value={item}
                  disabledTillClicked
                ></TextInput>
                {index > 0 && (
                  <button
                    id=""
                    className="action-button base-button evo-blue mb-20"
                    onClick={() => handleDeleteEmailField(index)}
                    type="button"
                  >
                    Delete Email
                  </button>
                )}
              </div>
            );
          })}

          <button
            id=""
            className="action-button base-button evo-gunmetal mb-20 fb-100"
            onClick={handleAddEmailField}
            type="button"
          >
            Add Email
          </button>

          {state.phoneNumbers.map((item, index) => {
            return (
              <div
                className="form-group fb-100 ml-0"
                key={`phoneNumber-${index}`}
              >
                <label className="phoneInputLabel" htmlFor="phone">
                  Phone Number #{index + 1}
                </label>
                <PhoneInput
                  placeholder="Phone Number"
                  value={item.number}
                  name={`prospectPhone[${index}]`}
                  onChange={(value) =>
                    dispatch({
                      type: "phoneNumberChanged",
                      index: index,
                      value: value,
                    })
                  }
                  onCountryChange={(value) =>
                    dispatch({
                      type: "phoneCountryCodeChanged",
                      index: index,
                      value: value,
                    })
                  }
                  defaultCountry={item.countryCode}
                  className={`${
                    state.phoneEnabled ? "" : "phoneDisabledTillClicked"
                  }`}
                  onFocus={() =>
                    dispatch({
                      type: "fieldChanged",
                      field: "phoneEnabled",
                      value: true,
                    })
                  }
                />
                {index > 0 && (
                  <button
                    id=""
                    className="action-button base-button evo-blue mb-20"
                    onClick={() => handleDeletePhoneNumberField(index)}
                    type="button"
                  >
                    Delete Phone Number
                  </button>
                )}
              </div>
            );
          })}

          <button
            id=""
            className="action-button base-button evo-gunmetal mb-20  fb-100"
            onClick={handleAddPhoneNumberField}
            type="button"
          >
            Add Phone Number
          </button>

          <h3 className="mt-20 centered fb-100 mr-0 mb-20">
            Authorized Signers
          </h3>

          {state?.additionalUsers?.map((item, index) => {
            return (
              <Fragment key={index}>
                {/* <div className="two-column"> */}
                <TextInput
                  name={`firstName[${index}]`}
                  label={`First Name`}
                  type="text"
                  handleChange={(e) => {
                    dispatch({
                      type: "additionalUserFieldChanged",
                      index: index,
                      field: "firstName",
                      value: e.currentTarget.value,
                    });
                  }}
                  key={`firstName[${index}]-key`}
                  containerClasses="ml-0 mr-2pct-important"
                  value={item?.firstName}
                  // disabledTillClicked
                ></TextInput>

                <TextInput
                  name={`lastName[${index}]`}
                  label={`Last Name`}
                  type="text"
                  handleChange={(e) => {
                    dispatch({
                      type: "additionalUserFieldChanged",
                      index: index,
                      field: "lastName",
                      value: e.currentTarget.value,
                    });
                  }}
                  key={`lastName[${index}]-key`}
                  containerClasses="mr-0 ml-2pct"
                  value={item?.lastName}
                  // disabledTillClicked
                ></TextInput>

                <TextInput
                  name={`email[${index}]`}
                  label="Email"
                  handleChange={(e) => {
                    dispatch({
                      type: "additionalUserFieldChanged",
                      index: index,
                      field: "email",
                      value: e.currentTarget.value,
                    });
                  }}
                  value={item?.email}
                  // disabledTillClicked
                  containerClasses="fb-100 ml-0"
                  type="email"
                ></TextInput>

                <div
                  className="form-group fb-100 ml-0 mr-0"
                  key={`phoneNumber-${index}`}
                >
                  <label className="phoneInputLabel" htmlFor="phone">
                    Phone Number
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    value={item?.phoneNumber?.number}
                    name={`additionalUserPhoneNumber[${index}]`}
                    onChange={(value) => {
                      dispatch({
                        type: "additionalUserPhoneFieldChanged",
                        index: index,
                        field: "number",

                        value: value,
                      });
                    }}
                    onCountryChange={(value) =>
                      dispatch({
                        type: "additionalUserPhoneFieldChanged",
                        index: index,
                        field: "countryCode",
                        value: value,
                      })
                    }
                    defaultCountry={item?.phoneNumber?.countryCode}
                    // className={`${
                    //   state.phoneEnabled ? "" : "phoneDisabledTillClicked"
                    // }`}
                    // onFocus={() =>
                    //   dispatch({
                    //     type: "fieldChanged",
                    //     field: "phoneEnabled",
                    //     value: true,
                    //   })
                    // }
                  />
                </div>

                {/* 
                <CreatableSelect
              key={`selectedOwner-${
                state.prospectTags && state.prospectTags[0]
              }`}
              className={`tag-select prospect-form-select  ${
                state.tagFieldEnabled ? "" : "selectDisabledTillClicked"
              }`}
              classNamePrefix="select"
              isMulti
              value={state.prospectTags}
              options={state.prospectTagOptions}
              //   defaultValue={state.}
              placeholder="Prospect Tags"
              onChange={handleProspectTagSelection}
              name="prospectTags"
              // onFocus={() =>
              //   dispatch({
              //     type: "fieldChanged",
              //     field: "tagFieldEnabled",
              //     value: true,
              //   })
              // }
            ></CreatableSelect> */}

                <div className="form-group fb-100 ml-0">
                  <label className="base-label" htmlFor="selectedStage">
                    Relationship to Primary Contact
                  </label>
                  <CreatableSelect
                    key={`relationship-${item?.relationship}`}
                    className={`prospect-form-select`}
                    classNamePrefix="select"
                    value={item?.relationship}
                    options={state.additionalUserRelationshipOptions}
                    placeholder="Select a Relationship"
                    onChange={(value) => {
                      dispatch({
                        type: "additionalUserFieldChanged",
                        index: index,
                        field: "relationship",
                        value: value,
                      });
                    }}
                    name={`relationship[${index}]`}
                  ></CreatableSelect>
                </div>

                {index > 0 && (
                  <button
                    id=""
                    className="action-button base-button evo-blue mb-20"
                    onClick={() => handleDeleteAdditionalUserField(index)}
                    type="button"
                  >
                    Delete Additional User
                  </button>
                )}
              </Fragment>
            );
          })}

          <button
            id=""
            className="action-button base-button evo-gunmetal mb-20  fb-100"
            onClick={handleAddAdditionalUserField}
            type="button"
          >
            Add Authorized Signer
          </button>

          <input type="submit" value="Submit"></input>
        </form>
      )}

      <>{/* <JSONPretty id="json-pretty" data={state}></JSONPretty> */}</>
    </Fragment>
  );
};

export default SingleProspectContent;
