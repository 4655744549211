import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { Fragment } from "react";

const columns = [
  {
    name: "View",
    sortable: false,
    cell: (row) => {
      return <Link to={`/trips/${row._id}`}>View</Link>;
    },
    width: "70px",
  },
  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    width: "130px",
    cell: (row) => {
      return moment(row.createdAt).format("M/D/YY - HHmm");
    },
  },

  {
    name: "Created By",
    selector: "createdBy.name",
    sortable: true,
    width: "150px",
  },
  {
    name: "Trip #",
    selector: "tripTag",
    sortable: true,
    width: "110px",
  },

  {
    name: "Client Name",
    selector: "clientName",
    sortable: true,
    width: "110px",
  },

  {
    name: "Operator",
    selector: "operatorName",
    sortable: true,
    width: "110px",
  },
  {
    name: "Departure Date",
    selector: "departureDate",
    sortable: true,
    width: "110px",
    cell: (row) => {
      return moment(row.departureDate).format("M/D/YY");
    },
  },

  {
    name: "Return Date",
    selector: "returnDate",
    sortable: true,
    width: "110px",
    cell: (row) => {
      if (row.returnDate) {
        return moment(row.returnDate).format("M/D/YY");
      }

      return "-";
    },
  },

  {
    name: "Contracts",
    selector: "contractListString",
    sortable: false,
    width: "150px",
  },

  {
    name: "OFRs",
    selector: "ofrListString",
    sortable: false,
    width: "150px",
  },

  {
    name: "Routing",
    selector: "latestRoutingString",
    sortable: false,
    width: "200px",
  },

  {
    name: "Next Penalty",
    selector: "nextPenalty",
    sortable: false,
    width: "150px",
    cell: (row) => {
      return row.nextPenalty ? moment(row.nextPenalty).format("M/D/YY") : "-";
    },
  },

  // {
  //   name: "Send Quote",
  //   sortable: false,
  //   cell: (row) => {
  //     let newQuote = <Link to={`/leads/${row._id}/quotes/new`}>New Quote</Link>;

  //     if (row.assigneeId) {
  //       return newQuote;
  //     }

  //     return "Assign Lead First";
  //   },
  //   width: "130px",
  // },

  // {
  //   name: "From ATC",
  //   selector: "isFromATC",
  //   sortable: true,
  //   width: "100",
  //   cell: (row) => {
  //     return row.isFromATC ? "Yes" : "False";
  //   },
  // },

  // {
  //   name: "Priority",
  //   selector: "lowestPriority",
  //   sortable: true,
  //   width: "100",
  //   // cell: (row) => {
  //   //   return moment(
  //   //     row.auditTrail
  //   //       .filter((item) => item.eventType === "matchAdded")
  //   //       .sort((a, b) => new Date(b.date) - new Date(a.date))[0].timestamp
  //   //   ).format("llll");
  //   // },
  // },

  // {
  //   name: "CL Created At",
  //   selector: "originCreatedAt",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return moment(row.originCreatedAt).tz("America/New_York").format("llll");
  //   },
  // },

  // {
  //   name: "View CL",
  //   selector: "_id",
  //   sortable: true,
  //   width: "100px",
  //   cell: (row) => {
  //     return <Link to={`/flight-matches/${row._id}`}>View</Link>;
  //   },
  // },

  // // {
  // //   name: "View CL Origin Record",
  // //   selector: "parentRecordId",
  // //   sortable: true,
  // //   width: "220px",
  // //   cell: (row) => {
  // //     let slug, recordLabel;

  // //     console.log(row.origin);
  // //     if (row.origin === "atc") {
  // //       slug = "atc";
  // //       recordLabel = "ATC Record";
  // //     } else {
  // //       slug = "leads";
  // //       recordLabel = "Lead";
  // //     }
  // //     return (
  // //       <Link to={`/${slug}/${row.parentRecordId}`}>View {recordLabel}</Link>
  // //     );
  // //   },
  // // },

  // {
  //   name: "Assignee",
  //   selector: "assigneeName",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => row.assigneeName || "-",
  // },

  // {
  //   name: "CL Departure Date",
  //   selector: "departureDateString",
  //   sortable: true,
  //   width: "200px",
  //   cell: (row) => {
  //     return moment(row.departureDateString).utc().format("ddd, MMM Do YYYY");
  //   },
  // },

  // {
  //   name: "Departure Airport",
  //   selector: "departureAirport.airportName",
  //   sortable: true,
  //   width: "350px",
  // },

  // {
  //   name: "Arrival Airport",
  //   selector: "arrivalAirport.airportName",
  //   sortable: true,
  //   width: "350px",
  // },

  // {
  //   name: "Matches",
  //   selector: "matchData",
  //   sortable: true,
  //   width: "550px",
  //   cell: (row) => {
  //     let matchesSorted = row.flightMatches.sort(
  //       (a, b) => a.priority - b.priority
  //     );

  //     return matchesSorted.map((flight, index) => {
  //       let matchData = row.matchData.filter(
  //         (item) => item._id === flight.legId
  //       )[0];

  //       let recordId, slug;

  //       if (matchData.isFromLead) {
  //         recordId = matchData.leadId;
  //         slug = "leads";
  //         console.log("isFromLeads", recordId);
  //       }

  //       if (matchData.isFromATC) {
  //         recordId = matchData.atcId;
  //         slug = "atc";
  //         console.log("isFromATC");
  //       }

  //       slug = "flight-matches";
  //       recordId = matchData._id;

  //       console.log("RECORD", matchData);

  //       let matches = (
  //         <Fragment
  //           key={`fragment-${recordId}-isPrimaryLeg-${matchData.isPrimaryLeg}`}
  //         >
  //           <Link
  //             key={recordId}
  //             className="matchData"
  //             to={`/${slug}/${recordId}`}
  //           >
  //             {matchData.departureAirport.code} -{" "}
  //             {matchData.arrivalAirport.code}
  //           </Link>
  //           {row.matchData.length - 1 !== index && ", "}{" "}
  //         </Fragment>
  //       );

  //       if (
  //         matchData.stage === "inProgressTemp" ||
  //         matchData.stage === "warmProspect" ||
  //         matchData.stage === "hotProspect" ||
  //         matchData.stage === "noReply" ||
  //         matchData.stage === "archived" ||
  //         matchData.stage === "unqualified" ||
  //         matchData.stage.includes("closed")
  //       ) {
  //         return "Match Failed " + matchData.stage + " " + matchData._id;
  //       }

  //       return matches;
  //     });
  //   },
  // },

  {
    name: "Stage",
    selector: "stage",
    sortable: true,
    width: "130px",
    cell: (row) => {
      if (row.stage === "newLead") {
        return "New Lead";
      } else if (row.stage === "inProgress") {
        return "In Progress";
      } else if (row.stage === "archived") {
        return "Archived";
      } else if (row.stage === "warmProspect") {
        return "Warm Prospect";
      } else if (row.stage === "unqualified") {
        return "Unqualified";
      } else if (row.stage === "evoEmptyLeg") {
        return "Evo Empty Leg";
      } else if (row.stage === "repeatClientRequest") {
        return "Repeat Client Request";
      } else if (row.stage === "newClientRequest") {
        return "New Client Request";
      } else if (row.stage === "signedContract") {
        return "Signed Contract";
      } else if (row.stage === "noReply") {
        return "No Reply";
      } else if (row.stage === "hotProspect") {
        return "Hot Prospect";
      } else if (row.stage === "newTrip") {
        return "New Trip";
      }
    },
  },

  // {
  //   name: "Leg Created At",
  //   selector: "createdAt",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return moment(row.createdAt).tz("America/New_York").format("llll");
  //   },
  // },

  // {
  //   name: "Last Match Event",
  //   selector: "auditTrail",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return moment(
  //       row.auditTrail
  //         .filter((item) => item.eventType === "matchAdded")
  //         .sort((a, b) => new Date(b.date) - new Date(a.date))[0].timestamp
  //     )
  //       .tz("America/New_York")
  //       .format("llll");
  //   },
  // },

  // {
  //   name: "Contains FOM Match",
  //   selector: "containsFOM",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return row.containsFOM ? "Yes" : "No";
  //   },
  // },

  // {
  //   name: "Is Part of Round Trip",
  //   selector: "isPartOfRoundTrip",
  //   sortable: true,
  //   width: "230px",
  //   cell: (row) => {
  //     return row.isPartOfRoundTrip ? "Yes" : "No";
  //   },
  // },

  // {
  //   name: "Assigned To",
  //   selector: "assigneeName",
  //   sortable: true,
  //   width: "150px",
  // },

  // {
  //   name: "Assigned At",
  //   selector: "assignedAt",
  //   sortable: true,
  //   width: "200px",
  //   cell: (row) => {
  //     if (row.assignedAt) {
  //       return moment(row.assignedAt).format("llll");
  //     }

  //     return "-";
  //   },
  // },
  // {
  //   name: "First Name",
  //   selector: "firstName",
  //   sortable: true,
  //   width: "150px",
  // },
  // {
  //   name: "Last Name",
  //   selector: "lastName",
  //   sortable: true,
  //   width: "150px",
  // },
  // {
  //   name: "Phone",
  //   selector: "phone",
  //   sortable: true,
  //   width: "150px",
  // },
  // // {
  // //   name: "Phone Country Code",
  // //   selector: "phoneCountryCode",
  // //   sortable: true,
  // //   width: "220px",
  // // },
  // {
  //   name: "Email",
  //   selector: "email",
  //   sortable: true,
  //   width: "150px",
  // },
  // {
  //   name: "Comments",
  //   selector: "comments",
  //   sortable: true,
  //   width: "350px",
  // },
  // {
  //   name: "Trip Type",
  //   selector: "tripType",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => (row.tripType === 1 ? "One Way" : "Round Trip"),
  // },

  // {
  //   name: "Return Date",
  //   selector: "estimateData[0].returnDate",
  //   sortable: true,
  //   width: "200px",
  //   cell: (row) =>
  //     row.estimateData[0].returnDate ? row.estimateData[0].returnDate : "-",
  // },

  // {
  //   name: "Departure Airport",
  //   selector: "estimateData[0].departureAirportName",
  //   sortable: true,
  //   width: "350px",
  // },

  // {
  //   name: "Arrival Airport",
  //   selector: "estimateData[0].arrivalAirportName",
  //   sortable: true,
  //   width: "350px",
  // },

  // {
  //   name: "Passengers",
  //   selector: "estimateData[0].passengerCount",
  //   sortable: true,
  //   width: "150px",
  // },
  // {
  //   name: "Company",
  //   selector: "companyName",
  //   sortable: true,
  //   width: "150px",
  // },

  // {
  //   name: "Opportunity ID",
  //   selector: "orOpportunityId",
  //   sortable: true,
  //   width: "190px",
  //   cell: (row) => (
  //     <a
  //       href={`https://app1a.outreach.io/opportunities/${row.orOpportunityId}/activity`}
  //       target="_blank"
  //     >
  //       {row.orOpportunityId}
  //     </a>
  //   ),
  // },
  // {
  //   name: "Prospect ID",
  //   selector: "orProspectId",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => (
  //     <a
  //       href={`https://app1a.outreach.io/prospects/${row.orProspectId}/activity`}
  //       target="_blank"
  //     >
  //       {row.orProspectId}
  //     </a>
  //   ),
  // },
  // {
  //   name: "Lead Origin",
  //   selector: "leadOrigin",
  //   sortable: true,
  //   width: "150px",
  //   cell: (row) => {
  //     let formatted = "";
  //     if (row.leadOrigin === "qfs") {
  //       formatted = "Flight Deck - QFS";
  //     } else if (row.leadOrigin === "liveAPI") {
  //       formatted = "Live API";
  //     } else if (row.leadOrigin === "phoneCall") {
  //       formatted = "Phone Call";
  //     } else if (row.leadOrigin === "flightDeckInternal") {
  //       formatted = "Flight Deck - Internal";
  //     } else if (row.leadOrigin === "flightDeckExternal") {
  //       formatted = "Flight Deck - External";
  //     } else {
  //       formatted = "Unset";
  //     }
  //     return formatted;
  //   },
  // },
];

export default columns;
